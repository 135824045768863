// <!-- API -->
import { ref, computed } from 'vue';
import {
    createAccountUser,
    updateAccountUserById,
    deleteAccountUserById,
    resetAccountUserPassword,
} from '@/api/v1/accounts/users';

// <!-- COMPONENTS -->
import UserManagerTableIcons from '~AccountSettings/components/UserManagerTableIcons.vue';

// <!-- UTILITIES -->
import pick from 'just-pick';
import isNil from 'lodash-es/isNil';
import { formatISO } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import { useUserIndex } from '@/hooks/cache/useUserIndex';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */

// <!-- COMPOSABLE -->
/**
 * Provides access to all composable submodules.
 */
class UserManager {
    /**
     * Instantiate a new UserManager composable.
     * @param {Object} [props] Props to pass to the UserManager.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [props.grid] AgGrid composable.
     * @param {ReturnType<useUserIndex>} [props.users] User index composable.
     */
    constructor(props = {}) {
        // Deconstruct parameters.
        const { store, alerts, grid, users } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();

        /** @type {ReturnType<useUserIndex>} */
        this.users = users ?? useUserIndex();

        /** @type {UserManagerConstants} */
        this.constants = new UserManagerConstants(this);

        /** @type {UserManagerState} */
        this.state = new UserManagerState(this);

        /** @type {UserManagerCache} */
        this.cached = new UserManagerCache(this);

        /** @type {UserManagerAPI} */
        this.api = new UserManagerAPI(this);

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {UserManager['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {UserManager['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {UserManager['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {UserManager['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<UserManagerConstants, 'initialize'> & Omit<UserManagerState, 'initialize'> & Omit<UserManagerCache, 'initialize' | 'initStatusConditionals' | 'initOpenModalConditionals' | 'initUserTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     * @returns {UserManagerAPI['events'] & UserManagerAPI['methods']}
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.events,
            ...$context.api.methods,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerConstants {
    /**
     * Instantiate submodule.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);
        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ([
            'addUser',
            'editUser',
            'viewUser',
            'confirmDelete',
            'confirmResetPassword',
        ]);
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerState {
    /**
     * Instantiate submodule.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {V.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        // ==== OPEN MODALS ====
        /** @type {V.Ref<'addUser' | 'editUser' | 'confirmDelete' | 'confirmResetPassword'>} Only one modal can be open at a time. */
        this.openModal = ref(null);

        // ==== USER INDEX ====
        /** @type {V.Ref<Map<Number, User & { accessLevel?: String }>>} */
        this.userIndex = ref(new Map());

        // ==== USER TARGETS ====
        /** @type {V.Ref<Pick<User & { accessLevel?: String }, 'username' | 'email' | 'accessLevel'>>} User target. */
        this.userToAdd = ref(null);

        /** @type {V.Ref<Pick<User & { accessLevel?: String }, 'id' | 'username' | 'email' | 'accessLevel'>>} User target. */
        this.userToEdit = ref(null);

        /** @type {V.Ref<Pick<User & { accessLevel?: String }, 'id' | 'username' | 'email' | 'accessLevel'>>} User target. */
        this.userToDelete = ref(null);

        /** @type {V.Ref<Pick<User & { accessLevel?: String }, 'id' | 'username' | 'email' | 'accessLevel'>>} User target. */
        this.userToResetPassword = ref(null);

        // ==== AG GRID ====
        /** @type {V.Ref<Array<User>>} */
        this.rowData = ref([]);

        /** @type {V.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefs = ref([]);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
        // ==== CONDITIONALS (MODALS) ====
        this.initOpenModalConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                state.status.value === 'loading' ||
                this.context.users.isFetching.value === true
            );
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }

    /**
     * Initialize the open modal conditionals.
     */
    initOpenModalConditionals() {
        const { state } = this.context;

        /** @type {V.ComputedRef<Boolean>} */
        this.isAddUserModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'addUser';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isEditUserModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'editUser';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmDeleteModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'confirmDelete';
        });

        /** @type {V.ComputedRef<Boolean>} */
        this.isConfirmResetPasswordModalOpen = computed(() => {
            const currentOpenModal = state.openModal.value;
            return currentOpenModal === 'confirmResetPassword';
        });
    }
}

// ==== API ====
/**
 * @class
 * Submodule for the {@link UserManager} composable.
 */
class UserManagerAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {UserManager} context
     */
    constructor(context) {
        /** @type {UserManager} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const { state, store } = $api.context;

        /**
         * Format the ISO string into just its date component.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const useDateComponentFormat = (params) => {
            const value = params.value;
            if (!isNil(value) && value !== '') {
                const date = DateTimeISO.parse(value);
                const formatted = formatISO(date, {
                    format: 'extended',
                    representation: 'date',
                });
                return formatted;
            }
            return 'No Date Provided';
        };

        /**
         * Get keyed column definitions.
         */
        const getColumnSchema = () => {
            return {
                /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                icons: {
                    headerName: '',
                    field: 'id',
                    cellRendererFramework: UserManagerTableIcons,
                    lockPosition: true,
                    filter: false,
                    maxWidth: 120,
                    cellRendererParams: {
                        /**
                         * Handle editing of a user.
                         * @param {Object} event
                         * @param {Number} index User index.
                         */
                        handleEdit: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const user = state.userIndex.value.get(id);
                            $api.events.onClick.editUser(user);
                        },
                        /**
                         * Handle deletion of a user.
                         * @param {Object} event
                         * @param {Number} index User index.
                         */
                        handleDelete: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const user = state.userIndex.value.get(id);
                            $api.events.onClick.deleteUser(user);
                        },
                        /**
                         * Handle reseting user password.
                         * @param {Object} event
                         * @param {Number} index User index.
                         */
                        handleResetPassword: (event, index) => {
                            const id = state.rowData.value[index].id;
                            const user = state.userIndex.value.get(id);
                            $api.events.onClick.resetUserPassword(user);
                        },
                    },
                },
                username: {
                    headerName: 'Username',
                    field: 'username',
                    maxWidth: 130,
                    sort: 'desc',
                },
                email: {
                    headerName: 'User Email',
                    field: 'email',
                    minWidth: 450,
                },
                accessLevel: {
                    headerName: 'Access Level',
                    field: 'accessLevel',
                },
                createdAt: {
                    headerName: 'Date Created',
                    valueFormatter: $api.methods.formatDate,
                    field: 'createdAt',
                },
                updatedAt: {
                    headerName: 'Date Updated',
                    valueFormatter: $api.methods.formatDate,
                    field: 'updatedAt',
                },
                lastLoginAt: {
                    headerName: 'Last Login Date',
                    valueFormatter: $api.methods.formatDate,
                    field: 'lastLoginAt',
                },
            };
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return [
                schema.icons,
                schema.username,
                schema.email,
                schema.createdAt,
                schema.updatedAt,
                schema.accessLevel,
                schema.lastLoginAt,
            ];
        };

        /**
         * Get the current account.
         * @returns {store['state']['accounts']['account']}
         */
        const getCurrentAccount = () => {
            return store.state.accounts.account;
        };

        /**
         * Map access level into its corresponding display text.
         * @param {String} value
         */
        const getAccessLevelDisplayText = (value) => {
            const id = value.toLowerCase();
            switch (id) {
                case 'normal':
                    return 'Data Manager';
                case 'readonly':
                    return 'Data Analyst';
                case 'admin':
                    return 'Admin';
                default:
                    return value;
            }
        };

        /**
         * Create user index from array,
         * @param {User[]} users
         */
        const getUsersAsIndex = (users) => {
            /** @type {[ id: Number, user: User ][]} */
            const entries = users.map((u) => {
                /** @type {[ id: Number, user: User ]} */
                const entry = [u.id, { ...u }];
                return entry;
            });
            /** Get map. */
            return new Map(entries);
        };

        /**
         * Map
         * @param {User[]} users
         * @returns {(User & { accessLevel?: String })[]}
         */
        const getUsersAsRowData = (users) => {
            return users.map((user) => getUserAsRecord(user));
        };

        /**
         * Copy user and modify it for the row data.
         * @param {User & { accessLevel?: String }} user
         */
        const getUserAsRecord = (user) => ({
            ...user,
            accessLevel: getAccessLevelDisplayText(user?.accessLevel),
        });

        /**
         * Copy user from index as a selected user target.
         * @param {User['id']} id
         */
        const getUserAsTarget = (id) => {
            const source = state.userIndex.value.get(id);
            return {
                ...pick(source, 'id', 'username', 'email', 'accessLevel'),
            };
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            useDateComponentFormat,
            getColumnSchema,
            getColumnDefs,
            getCurrentAccount,
            getAccessLevelDisplayText,
            getUsersAsIndex,
            getUsersAsRowData,
            getUserAsRecord,
            getUserAsTarget,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /**
         * Set the open modal.
         * @param {'addUser' | 'editUser' | 'confirmDelete' | 'confirmResetPassword'} id
         */
        const setOpenModal = (id = null) => {
            state.openModal.value = id ?? null;
        };

        /**
         * Set user index instance.
         * @param {Map<Number, User & { accessLevel?: String }>} index
         */
        const setUserIndex = (index) => {
            state.userIndex.value = new Map(index.entries());
        };

        /**
         * Set user target for new user.
         * @param {Pick<User & { accessLevel: String }, 'username' | 'email' | 'accessLevel'>} target
         */
        const setAddUserTarget = (target) => {
            state.userToAdd.value = target;
        };

        /**
         * Set user target for the corresponding id.
         * @param {User['id']} id
         */
        const setEditUserTarget = (id) => {
            const { getUserAsTarget } = $api.getters;
            const target = id ? getUserAsTarget(id) : null;
            state.userToEdit.value = target;
        };

        /**
         * Set user target for the corresponding id.
         * @param {User['id']} id
         */
        const setDeleteUserTarget = (id) => {
            const { getUserAsTarget } = $api.getters;
            const target = id ? getUserAsTarget(id) : null;
            state.userToDelete.value = target;
        };

        /**
         * Set user target for the corresponding id.
         * @param {User['id']} id
         */
        const setResetPasswordUserTarget = (id) => {
            const { getUserAsTarget } = $api.getters;
            const target = id ? getUserAsTarget(id) : null;
            state.userToResetPassword.value = target;
        };

        /**
         * Set the row data.
         * @param {User[]} data
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setLoading,
            setOpenModal,
            setUserIndex,
            setRowData,
            get setUserTarget() {
                return {
                    toAdd: setAddUserTarget,
                    toEdit: setEditUserTarget,
                    toDelete: setDeleteUserTarget,
                    toResetPassword: setResetPasswordUserTarget,
                };
            },
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When users index is loaded/refreshed,
         * update the row data, with mapped account access levels.
         * @param {User[]} accountUsers
         */
        const onUpdateUsers = (accountUsers) => {
            const { getUsersAsIndex, getUsersAsRowData } = $api.getters;
            const { setUserIndex, setRowData } = $api.setters;
            const accountUserIndex = getUsersAsIndex(accountUsers);
            const accountUserData = getUsersAsRowData(accountUsers);
            setUserIndex(accountUserIndex);
            setRowData(accountUserData);
        };

        /**
         * On click add user.
         */
        const onClickAddUser = () => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toAdd({ username: '', email: '', accessLevel: '' });
            open.addUserModal();
        };

        /**
         * On click edit user.
         * @param {User} target
         */
        const onClickEditUser = (target) => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toEdit(target.id);
            open.editUserModal();
        };

        /**
         * On click delete user.
         * @param {User} target
         */
        const onClickDeleteUser = (target) => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toDelete(target.id);
            open.confirmDeleteModal();
        };

        /**
         * On click reset password for the target user.
         * @param {User} target
         */
        const onClickResetUserPassword = (target) => {
            const { open } = $api.methods;
            const { setUserTarget } = $api.setters;
            setUserTarget.toResetPassword(target.id);
            open.confirmResetPasswordModal();
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelAddUser = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.addUserModal();
            setUserTarget.toAdd(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelEditUser = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.editUserModal();
            setUserTarget.toEdit(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelDeleteUser = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.confirmDeleteModal();
            setUserTarget.toDelete(null);
        };

        /**
         * Handle when action is cancelled.
         */
        const onCancelResetUserPassword = () => {
            const { close } = $api.methods;
            const { setUserTarget } = $api.setters;
            close.confirmResetPasswordModal();
            setUserTarget.toResetPassword(null);
        };

        /**
         * Submit the action.
         * @param {Pick<User & { accessLevel: String }, 'username' | 'email' | 'accessLevel'>} user
         */
        const onSubmitAddUser = async (user) => {
            const { close, refreshUsers } = $api.methods;
            const { getCurrentAccount } = $api.getters;
            const { setLoading, setUserTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.addUserModal();
            try {
                console.time(`[user::add]`);
                setLoading('loading');
                // ==== EDIT USER ====
                const account = getCurrentAccount();
                const payload = {
                    user_name: user.username,
                    email: user.email,
                    access_level: user.accessLevel,
                };
                await createAccountUser(account, payload);
                pushAlert(
                    createAlert({
                        id: `add-success`,
                        type: 'success',
                        title: `Edit User Success`,
                        messages: [`User successfully added to your account.`],
                        dismissable: true,
                    })
                );
                // ==== FETCH INDEX ====
                await refreshUsers(true);
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `add-error`,
                        type: 'error',
                        title: `Add User Error`,
                        messages: [
                            'Error adding the user, please try again',
                            error?.message,
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[user::add]`);
                setUserTarget.toAdd(null);
            }
        };

        /**
         * Submit the action.
         * @param {Pick<User & { accessLevel: String }, 'username' | 'email' | 'accessLevel'>} user
         */
        const onSubmitEditUser = async (user) => {
            const { close, refreshUsers } = $api.methods;
            const { getCurrentAccount } = $api.getters;
            const { setLoading, setUserTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.editUserModal();
            try {
                console.time(`[user::edit]`);
                setLoading('loading');
                // ==== EDIT USER ====
                const account = getCurrentAccount();
                const request = state.userToEdit.value;
                const payload = {
                    user_name: user.username,
                    email: user.email,
                    access_level: user.accessLevel,
                };
                await updateAccountUserById(account, request, payload);
                pushAlert(
                    createAlert({
                        id: `edit-success`,
                        type: 'success',
                        title: `Edit User Success`,
                        messages: ['User successfully updated.'],
                        dismissable: true,
                    })
                );
                // ==== FETCH INDEX ====
                await refreshUsers(true);
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `edit-error`,
                        type: 'error',
                        title: `Edit User Error`,
                        messages: [
                            'Error editing the user, please try again',
                            error?.message,
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[user::edit]`);
                setUserTarget.toEdit(null);
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitDeleteUser = async () => {
            const { close, refreshUsers } = $api.methods;
            const { getCurrentAccount } = $api.getters;
            const { setLoading, setUserTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.confirmDeleteModal();
            try {
                console.time(`[user::delete]`);
                setLoading('loading');
                // ==== DELETE USER ====
                const account = getCurrentAccount();
                const request = state.userToDelete.value;
                await deleteAccountUserById(account, request);
                pushAlert(
                    createAlert({
                        id: `delete-success`,
                        type: 'success',
                        title: `Delete User Success`,
                        messages: ['User successfully deleted.'],
                        dismissable: true,
                    })
                );
                // ==== FETCH INDEX ====
                await refreshUsers(true);
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `delete-error`,
                        type: 'error',
                        title: `Delete User Error`,
                        messages: [
                            'Error deleting user, please try again',
                            error?.message,
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[user::delete]`);
                setUserTarget.toDelete(null);
            }
        };

        /**
         * Submit the action.
         */
        const onSubmitResetUserPassword = async () => {
            const { close } = $api.methods;
            const { getCurrentAccount } = $api.getters;
            const { setLoading, setUserTarget } = $api.setters;
            const { pushAlert, createAlert } = alerts.methods;
            close.confirmResetPasswordModal();
            try {
                console.time(`[user::reset::password]`);
                setLoading('loading');
                // ==== RESET USER PASSWORD ====
                const account = getCurrentAccount();
                const request = state.userToResetPassword.value;
                await resetAccountUserPassword(account, request);
                pushAlert(
                    createAlert({
                        id: `reset-password-success`,
                        type: 'success',
                        title: `Reset Password Success`,
                        messages: ['Email was sent to user for password reset'],
                        dismissable: true,
                    })
                );
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                pushAlert(
                    createAlert({
                        id: `reset-password-error`,
                        type: 'error',
                        title: `Reset Password Error`,
                        messages: [
                            'Error resetting password, please try again',
                        ],
                        dismissable: true,
                    })
                );
            } finally {
                console.timeEnd(`[user::reset::password]`);
                setUserTarget.toResetPassword(null);
            }
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const { getColumnDefs } = $api.getters;
            state.colDefs.value = [...getColumnDefs()];
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onUpdateUsers,
            get onClick() {
                return {
                    addUser: onClickAddUser,
                    editUser: onClickEditUser,
                    deleteUser: onClickDeleteUser,
                    resetUserPassword: onClickResetUserPassword,
                };
            },
            get onCancel() {
                return {
                    addUser: onCancelAddUser,
                    editUser: onCancelEditUser,
                    deleteUser: onCancelDeleteUser,
                    resetUserPassword: onCancelResetUserPassword,
                };
            },
            get onSubmit() {
                return {
                    addUser: onSubmitAddUser,
                    editUser: onSubmitEditUser,
                    deleteUser: onSubmitDeleteUser,
                    resetUserPassword: onSubmitResetUserPassword,
                };
            },
        };
    }

    initMethods() {
        const $api = this;
        const { state, users } = $api.context;

        /**
         * Update the row data after requesting account users from the cached index.
         * @param {Boolean} [forceReload]
         */
        const refreshUsers = async (forceReload = false) => {
            const { setLoading } = $api.setters;
            try {
                console.time(`[users::index] - Refreshing User index:`);
                setLoading('loading');
                // ==== REFRESH ====
                const accountUsers = await users.refreshUserIndex(forceReload);
                $api.events.onUpdateUsers(accountUsers ?? []);
                // ==== END ====
                setLoading('success');
            } catch (error) {
                setLoading('failure');
                throw error;
            } finally {
                console.timeEnd(`[users::index] - Refreshing User index:`);
            }
        };

        /** Open modal. */
        const openAddUserModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('addUser');
        };

        /** Open modal. */
        const openEditUserModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('editUser');
        };

        /** Open modal. */
        const openConfirmDeleteModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('confirmDelete');
        };

        /** Open modal. */
        const openConfirmResetPasswordModal = () => {
            const { setOpenModal } = $api.setters;
            setOpenModal('confirmResetPassword');
        };

        /**
         * Close modal if it matches.
         * @param {'addUser' | 'editUser' | 'confirmDelete' | 'confirmResetPassword'} id
         */
        const closeModalIfOpen = (id) => {
            if (state.openModal.value === id) {
                // Close modal if it matches.
                $api.setters.setOpenModal(null);
            }
        };

        /** Event triggers and methods. */
        this.methods = {
            refreshUsers,
            get open() {
                return {
                    addUserModal: openAddUserModal,
                    editUserModal: openEditUserModal,
                    confirmDeleteModal: openConfirmDeleteModal,
                    confirmResetPasswordModal: openConfirmResetPasswordModal,
                };
            },
            get close() {
                return {
                    addUserModal: () => closeModalIfOpen('addUser'),
                    editUserModal: () => closeModalIfOpen('editUser'),
                    confirmDeleteModal: () => closeModalIfOpen('confirmDelete'),
                    confirmResetPasswordModal: () =>
                        closeModalIfOpen('confirmResetPassword'),
                };
            },
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 */
export const useUserManager = () => {
    const context = new UserManager();
    return context.initialize();
};

// <!-- DEFAULT -->
export default useUserManager;
