export default function () {
    return {
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        },
        onColumnResized(event) {
            this.gridApi.refreshCells();
        },
        defaultColDef: {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            minWidth: 150,
            flex: 1,
            cellClass: 'leading-5 py-4 break-normal',
        },
    };
}
