<template>
    <div class="flex main-section">
        <div class="flex-grow overflow-y-auto print:overflow-y-visible pb-6">
            <div class="border-b border-gray-200 outer-border">
                <div class="max-w-7xl mx-auto px-5 pt-6 sm:pt-0">
                    <SubNav :subTabs="tabs" />
                    <PageTitle></PageTitle>
                </div>
            </div>
            <slot name="mainContent">T2</slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SubNav from '@/components/SubNav.vue';
    import PageTitle from '@/components/PageTitle.vue';

    // <!-- CONSTANTS -->
    /** Tabs for the settings container. */
    const tabs = [
        { name: 'User Manager', to: '/account/user-manager', current: false },
    ];

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SettingsContainer',
        components: {
            SubNav,
            PageTitle,
        },
        setup() {
            // ==== COMPOSE ====
            const router = useRouter();

            // ==== METHODS ====
            /**
             * Invoked when the component is created, at the end of the setup()
             * function call.
             */
            const onCreated = () => {
                // Update the current tab.
                for (let tabObj of tabs) {
                    if (tabObj.to === router.currentRoute.value.path) {
                        tabObj.current = true;
                    } else {
                        tabObj.current = false;
                    }
                }
            };

            // ==== LIFECYCLE ====
            onCreated();

            // ==== EXPOSE ====
            return {
                tabs,
            };
        },
    });
</script>

<style scoped>
    .main-section {
        min-height: calc(100vh - 4rem);
    }

    .outer-border div {
        border: 0;
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
