<template>
    <BasicModal
        :show="isAddUserModalOpen"
        @close="onCancel.addUser"
        hideCloseButton
    >
        <AddUserForm
            :sourceUser="userToAdd"
            :isEditing="true"
            :isLoading="isLoading"
            :onCancel="onCancel.addUser"
            :onSave="onSubmit.addUser"
        />
    </BasicModal>
    <BasicModal
        :show="isEditUserModalOpen"
        @close="onCancel.editUser"
        hideCloseButton
    >
        <EditUserForm
            :sourceUser="userToEdit"
            :isEditing="true"
            :isLoading="isLoading"
            :onCancel="onCancel.editUser"
            :onSave="onSubmit.editUser"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete User"
        confirmationText="Are you sure you want to delete this user? This action cannot be undone."
        :open="isConfirmDeleteModalOpen"
        @delete="onSubmit.deleteUser"
        @cancel="onCancel.deleteUser"
        @close="onCancel.deleteUser"
    />
    <ConfirmResetPasswordModal
        title="Reset User's Password"
        confirmationText="Are you sure you want to reset this user's password?"
        :open="isConfirmResetPasswordModalOpen"
        @submit="onSubmit.resetUserPassword"
        @cancel="onCancel.resetUserPassword"
        @close="onCancel.resetUserPassword"
    />
    <SettingsContainer>
        <template #mainContent>
            <PageWrapper>
                <LoadingWrapper :isLoading="isLoading">
                    <PageIntro>
                        <PageDescription>
                            View, add, and remove users for account.
                        </PageDescription>
                        <ModalButton
                            theme="primary"
                            label="Add User"
                            @click.prevent="onClick.addUser"
                        />
                    </PageIntro>
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="25"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                    >
                    </AgGridVue>
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </SettingsContainer>
    <div class="fixed top-10 right-0 mr-10 mt-2 min-w-18">
        <BaseAlert
            :alerts="alerts.state.alerts.value"
            @dismiss:alert="alerts.methods.dismissAlert($event)"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import BaseAlert from '@/components/alerts/BaseAlert.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import SettingsContainer from '~AccountSettings/components/wrappers/SettingsContainer.vue';
    import ConfirmResetPasswordModal from '~AccountSettings/components/ConfirmResetPasswordModal.vue';
    import AddUserForm from '~AccountSettings/components/AddUserForm.vue';
    import EditUserForm from '~AccountSettings/components/EditUserForm.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useUserManager } from '~AccountSettings/hooks/useUserManager';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UserManager',
        components: {
            BaseAlert,
            LoadingWrapper,
            ModalButton,
            SettingsContainer,
            PageWrapper,
            PageIntro,
            PageDescription,
            BasicModal,
            ConfirmDeleteModal,
            ConfirmResetPasswordModal,
            AgGridVue,
            AddUserForm,
            EditUserForm,
        },
        setup() {
            // ==== COMPOSABLES ====
            const manager = useUserManager();
            const { alerts, grid, data, actions } = manager;

            // ==== STATE ====
            const { defaultColDef } = grid;
            const { userIndex, userToAdd, userToEdit, rowData, colDefs } = data;

            // ==== COMPUTED PROPERTIES ====
            const {
                isLoading,
                isLoadedWithFailure,
                isLoadedWithSuccess,
                isAddUserModalOpen,
                isEditUserModalOpen,
                isConfirmDeleteModalOpen,
                isConfirmResetPasswordModalOpen,
            } = data;

            // ==== EVENTS ====
            const { onGridReady, onColumnResized } = grid;
            const { onClick, onCancel, onSubmit } = actions;

            // ==== LIFECYCLE ====
            onMounted(async () => {
                // Refresh the users on initial page load.
                await actions.refreshUsers(false);
            });

            // ==== EXPOSE ====
            return {
                manager,
                alerts,
                // STATE
                userToAdd,
                userToEdit,
                userIndex,
                defaultColDef,
                rowData,
                colDefs,
                // CONDITIONALS,
                isLoading,
                isLoadedWithFailure,
                isLoadedWithSuccess,
                isAddUserModalOpen,
                isEditUserModalOpen,
                isConfirmDeleteModalOpen,
                isConfirmResetPasswordModalOpen,
                // EVENTS
                onClick,
                onCancel,
                onSubmit,
                onGridReady,
                onColumnResized,
            };
        },
    });
</script>

<style lang="scss"></style>
