// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {globalThis.Account.Model} AccountResource */

// <!-- MODELS -->
import { User } from '@/models/v2/users';
import { AccountLevel } from '@/models/v1/accounts';
import { Account } from '@/models/v2/accounts';

// <!-- ROUTES -->
const ROUTES = {
    GET_ACCOUNT_USERS: (account) => `accounts/${account}/users`,
    CREATE_ACCOUNT_USER: (account) => `accounts/${account}/users`,
    GET_ACCOUNT_USER: (account, user) => `accounts/${account}/users/${user}`, // TODO: Implement route.
    UPDATE_ACCOUNT_USER: (account, user) => `accounts/${account}/users/${user}`,
    DELETE_ACCOUNT_USER: (account, user) => `accounts/${account}/users/${user}`,
    RESET_ACCOUNT_USER_PASSWORD: (user) => `users/${user}/reset-password`,
};

// <!-- REQUESTS -->

/**
 * Fetch the current user's accounts.
 * @param {Pick<AccountResource, 'id'>} account
 */
export const fetchAccountUsers = async (account = { id: 8 }) => {
    /** @type {import('axios').AxiosResponse<{ data: globalThis.User.Payload[] }>} */
    const response = await axios().get(ROUTES.GET_ACCOUNT_USERS(account.id));
    const collection = response.data.data;
    return collection.map((user) => new User({ payload: user }).toResource());
};

/**
 * Create new user using request body content.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Partial<Omit<globalThis.User.Payload, 'id'>>} request
 */
export const createAccountUser = async (account = { id: 8 }, request) => {
    /** @type {import('axios').AxiosResponse<import('@/api/v1').StatusResponse<{ user: globalThis.User.Payload }>, globalThis.User.Payload>} */
    const response = await axios().post(
        ROUTES.CREATE_ACCOUNT_USER(account.id),
        request
    );
    const { status, ...payload } = response.data.user;
    return new User({ payload }).toResource();
};

/**
 * Fetch specified user by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<globalThis.User.Payload, 'id'>} user
 */
export const deleteAccountUserById = async (account = { id: 8 }, user) => {
    /** @type {import('axios').AxiosResponse<import('@/api/v1').StatusResponse<{ user: UserPayload }>>} */
    const response = await axios().delete(
        ROUTES.DELETE_ACCOUNT_USER(account.id, user.id)
    );
    const { status } = response.data;
    return status;
};

/**
 * Fetch specified export by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<import('..').UserResource, 'id'>} user
 * @param {Partial<Omit<globalThis.User.Payload, 'id'>>} request
 */
export const updateAccountUserById = async (
    account = { id: 8 },
    user,
    request
) => {
    /** @type {import('axios').AxiosResponse<import('@/api/v1').StatusResponse<{ user: globalThis.User.Payload }>>} */
    const response = await axios().put(
        ROUTES.UPDATE_ACCOUNT_USER(account.id, user.id),
        request
    );
    const { status, ...payload } = response.data.user;
    return { status, user: new User({ payload }).toResource() };
};

/**
 * Reset password for supplied account aand user.
 * @param {{ account: Pick<globalThis.Account.Model, 'id'> } | { organization: Pick<globalThis.Organization.Model, 'id'> }} scope
 * @param {Pick<globalThis.User.Model, 'id'>} user
 * @returns {Promise<boolean>}}
 */
export const resetAccountUserPassword = async (scope, user) => {
    /** @type {{ organization?: number, account?: number }} */
    const request =
        'organization' in scope
            ? { organization: scope?.organization?.id }
            : { account: scope?.account?.id };

    /** @type {import('axios').AxiosResponse<void, { organization?: number, account?: number }>} */
    const response = await axios().post(
        ROUTES.RESET_ACCOUNT_USER_PASSWORD(user.id),
        request
    );

    if (response.status !== 204) {
        throw new Error('Failed to send password reset email.');
    }

    return true;
};

// <!-- EXPORTS -->
export default {
    Account,
    AccountLevel,
    User,
    fetchAccountUsers,
    // fetchAccountUserById,
    createAccountUser,
    updateAccountUserById,
    deleteAccountUserById,
    resetAccountUserPassword,
};
