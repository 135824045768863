<template>
    <!-- FALLBACK CONTENT -->
    <TransitionGroup
        name="list"
        tag="ul"
    >
        <li
            class="my-2"
            v-for="alert of alerts"
            :key="String(alert.id)"
        >
            <div
                class="rounded-md p-4"
                :class="alert.classes.outer"
            >
                <div class="flex">
                    <div class="flex-shrink-0">
                        <component
                            :is="getAlertIcon(alert.icon)"
                            class="h-5 w-5"
                            :class="alert.classes.alertIcon"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3">
                        <h3
                            class="text-xs font-medium"
                            :class="alert.classes.content"
                        >
                            <span
                                v-if="alert.title"
                                class="text-xs font-medium"
                                :class="alert.classes.content"
                            >
                                {{ alert.title }}:
                            </span>
                            {{ alert.content }}
                        </h3>
                        <div
                            v-if="!!alert.messages && alert.messages.length > 0"
                            class="mt-2 text-xs"
                            :class="alert.classes.messages"
                        >
                            <ul
                                role="list"
                                class="list-disc pl-5 space-y-1"
                            >
                                <li
                                    v-for="(message, index) of alert.messages"
                                    :key="`${String(
                                        alert.id
                                    )}-messages[${index}]`"
                                >
                                    {{ message }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        v-if="alert.dismissable"
                        class="ml-auto pl-3"
                    >
                        <div class="-mx-1.5 -my-1.5">
                            <button
                                type="button"
                                class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                :class="alert.classes.button"
                                @click="onAlertDismissal(alert)"
                            >
                                <span class="sr-only">Dismiss</span>
                                <XIcon
                                    class="h-4 w-4"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </TransitionGroup>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs } from 'vue';

    // <!-- COMPONENTS -->
    import {
        XCircleIcon,
        XIcon,
        ExclamationIcon,
        BadgeCheckIcon,
    } from '@heroicons/vue/outline';

    // <!-- COMPOSABLES -->
    import {
        ALERT_ICONS,
        ALERT_STYLES,
    } from '@/components/alerts/hooks/useAlerts';

    // <!-- EVENTS -->
    import { DismissAlertEvent } from '@/components/alerts/events';

    // <!-- TYPES -->
    /** @typedef {import('@/components/alerts/hooks/useAlerts').AlertDef} AlertDef */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'BaseAlert',
        components: {
            XCircleIcon,
            XIcon,
            ExclamationIcon,
            BadgeCheckIcon,
        },
        props: {
            alerts: {
                /** @type {V.PropType<AlertDef[]>} Array of alerts. */
                type: Array,
                default: () => [
                    {
                        id: 'alert-example-1',
                        icon: 'warning',
                        classes: ALERT_STYLES.get('warning'),
                        title: 'Alert Title',
                        content: 'This is an example alert message.',
                        messages: [],
                        dismissable: true,
                    },
                    {
                        id: 'alert-example-2',
                        icon: 'info',
                        classes: ALERT_STYLES.get('info'),
                        title: 'Message Title',
                        content: 'This is an example info message.',
                        messages: ['Note 1', 'Note 2'],
                        dismissable: true,
                    },
                    {
                        id: 'alert-example-3',
                        icon: 'error',
                        classes: ALERT_STYLES.get('error'),
                        title: 'Error Title',
                        content: 'This is an example error message.',
                        messages: ['Error 1'],
                        dismissable: false,
                    },
                    {
                        id: 'alert-example-4',
                        icon: 'success',
                        classes: ALERT_STYLES.get('success'),
                        title: 'Success Title',
                        content: 'This is an example success message.',
                        dismissable: true,
                    },
                ],
            },
        },
        emits: [DismissAlertEvent],
        setup(props, context) {
            // ==== PROPS ====
            const { alerts } = toRefs(props);

            // ==== METHODS ====
            /**
             * Dismiss alert if it's marked as dismissable.
             * @param {AlertDef} event
             */
            const onAlertDismissal = (event) => {
                if (event.dismissable) {
                    const alert = alerts.value.find(
                        (alert) => alert.id == event.id
                    );
                    context.emit(DismissAlertEvent, alert);
                }
            };
            /**
             * Get the icon for the alert.
             * @param {Parameters<ALERT_ICONS['get']>[0]} type
             */
            const getAlertIcon = (type) => {
                return ALERT_ICONS.get(type);
            };

            // ==== EXPOSE ====
            return {
                onAlertDismissal,
                getAlertIcon,
            };
        },
    });
</script>

<style scoped>
    /** See: https://vuejs.org/guide/built-ins/transition-group.html#enter-leave-transitions */
    .list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
        transition: all 0.5s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
    .list-leave-active {
        position: absolute;
    }
</style>
